/**!
 * isIE11
 *
 * @see https://stackoverflow.com/a/17447374/1136822
 */
import isIE11 from "../Utils/isIE11";
if (isIE11) {
	let html = document.querySelector('html');
	html.classList.add('is-ie11');
}
