// ---------- Config Setup ----------
import debounce from 'debounce'

// ---------- Polyfills ----------
import 'svgxuse'
import 'focus-visible/dist/focus-visible'
import 'wicg-inert/dist/inert'
import 'element-qsa-scope'
import 'focus-within'

// ---------- js class ----------
import './Components/jsClass'

// ---------- ie11 class ----------
import isIE11 from './Utils/isIE11'
import './Components/ie11Class'

// ---------- lazysizes ----------
import lazySizes from 'lazysizes'
//import 'lazysizes/plugins/attrchange/ls.attrchange'
import 'lazysizes/plugins/bgset/ls.bgset'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import 'lazysizes/plugins/respimg/ls.respimg'
Object.assign(lazySizes.cfg, { loadMode: 1 })

// ---------- sliders ----------
import flickitySliders from './Components/flickitySliders'

// ---------- sliders ----------
import switcher from './Components/selectUrlSwitcher'

// ---------- global elements and classes ----------
const html = document.querySelector('html')
const holderEl = document.querySelector('.holder')
const headerEl = document.querySelector('.header')
const navEl = document.querySelector('.nav')
const navLogoEl = navEl.querySelector('.nav-logo')
let previouslyActiveEl = null

const holderNavFixedClass = 'holder--navFixed'
const navOpenClass = 'nav--open'

// ---------- mobile menu ----------
import MobileMenu from './Components/mobileMenu'
const nav = new MobileMenu({
	openClass: 'nav--isOpen',
	closeClass: 'nav--isClosed',
	toggleSelector: '[data-nav-toggle]',
	maxWidth: 1500,
	onOpen() {
		//make the nav el not inert
		navEl.inert = false

		//add the nav open class (used for transitions)
		navEl.classList.add(navOpenClass)

		//set the previously active item
		previouslyActiveEl = document.activeElement || null

		//update the toggles for accessibility
		this.mobileMenuToggles.forEach(mobileMenuToggle => {
			if (mobileMenuToggle) {
				//set the aria expanded attributes
				mobileMenuToggle.setAttribute('aria-expanded', true)

				//set the aria label attribute
				mobileMenuToggle.setAttribute('aria-label', 'Close menu')
			}
		})

		//focus the nav logo
		navLogoEl.focus()
	},
	onClose() {
		//remove the nav open class (used for transitions)
		navEl.classList.remove(navOpenClass)

		//update the toggles for accessibility
		this.mobileMenuToggles.forEach(mobileMenuToggle => {
			if (mobileMenuToggle) {
				//set the aria expanded attributes
				mobileMenuToggle.setAttribute('aria-expanded', false)

				//set the aria label attribute
				mobileMenuToggle.setAttribute('aria-label', 'Open Menu')
			}
		})

		//attempt to restore focus
		if (previouslyActiveEl) {
			previouslyActiveEl.focus()
		}

		//make the nav el inert
		navEl.inert = true
	},
})
nav.close()

//determines if the nav if fixed
let isFixedNav = true
let checkIfIsFixedNav = function () {
	isFixedNav = true

	//is this a hero page?
	let isHero = document.querySelector('.hero')
	if (!isHero) {
		isFixedNav = false
	}

	//is the page wide enough
	let w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
	if (w < 768) {
		isFixedNav = false
	}

	if (isFixedNav) {
		holderEl.classList.add(holderNavFixedClass)
		nav.open()
	} else {
		holderEl.classList.remove(holderNavFixedClass)

		if (nav.isOpen()) {
			nav.close()
		}
	}
}
;['DOMContentLoaded', 'load', 'resize'].forEach(function (eventName) {
	window.addEventListener(eventName, debounce(checkIfIsFixedNav, 10, false))
})

//close the nav if it is clicked outside of
if (headerEl) {
	window.addEventListener('click', function (e) {
		if (!headerEl.contains(e.target) && !isFixedNav) {
			nav.close()
		}
	})
}

// ---------- svg sprite ----------
import './Components/svgSprite'

// ---------- responsive videos ----------
import responsiveVideos from './Components/responsiveVideos'

// ---------- video cover (for background video) ----------
import videoCover from './Components/videoCover'

// ---------- lightbox ----------
import MediaLightbox from './Components/lightbox'
let lightbox = new MediaLightbox({
	onOpen() {
		if (holderEl && !isIE11) {
			holderEl.inert = true
		}
	},
	onClose() {
		if (holderEl && !isIE11) {
			holderEl.inert = false
		}
	},
})
window.lightbox = lightbox

// ---------- content lightbox ----------
import ContentLightbox from './Components/contentLightbox'
const contentLightBoxHtmlClass = 'contentLightbox-isOpen'
let contentLightBox = new ContentLightbox({
	transitionInClass: 'fadeIn',
	onOpen(lightboxEl) {
		//set the previously active item
		previouslyActiveEl = document.activeElement

		//add the content lightbox class
		html.classList.add(contentLightBoxHtmlClass)

		//make the holder inert
		if (holderEl) {
			holderEl.inert = true
		}

		//change aria hidden
		lightboxEl.setAttribute('aria-hidden', 'false')

		//mark any toggles as expanded
		let lightboxId = lightboxEl.getAttribute('id')
		if (lightboxId) {
			let toggleEls = document.querySelectorAll('[data-content-lightbox="' + lightboxId + '"]')
			if (toggleEls) {
				toggleEls.forEach(toggleEl => {
					toggleEl.setAttribute('aria-expanded', 'true')
				})
			}
		}

		//try and give the h1 focus
		let lightboxH1 = lightboxEl.querySelector('h1')
		if (lightboxH1) {
			lightboxEl.focus()
		} else {
			lightboxEl.focus()
		}
	},
	onClose(lightboxEl) {
		//remove the content lightbox class
		html.classList.remove(contentLightBoxHtmlClass)

		//make the holder not inert
		if (holderEl) {
			holderEl.inert = false
		}

		//attempt to restore focus
		if (previouslyActiveEl) {
			previouslyActiveEl.focus()
		}

		//mark any toggles as not expanded
		let lightboxId = lightboxEl.getAttribute('id')
		if (lightboxId) {
			let toggleEls = document.querySelectorAll('[data-content-lightbox="' + lightboxId + '"]')
			if (toggleEls) {
				toggleEls.forEach(toggleEl => {
					toggleEl.setAttribute('aria-expanded', 'false')
				})
			}
		}

		//change aria hidden
		lightboxEl.setAttribute('aria-hidden', 'true')
	},
})

// ---------- scrollbar compensation ----------
import scrollbarCompensation from './Components/scrollbarCompensation'
scrollbarCompensation()

// ---------- mobile vh fix ----------
import mobileVh from './Components/mobileVh'
mobileVh()

// ---------- top bar ----------
import topbar from 'topbar'
topbar.config({
	barColors: { 0: 'rgba(0, 33, 57, 1)', 1: 'rgba(0, 33, 57, 1)' },
	shadowBlur: 0,
	shadowColor: 'rgba(0, 0, 0, 0)',
})

// ---------- nav active state updater ----------
import updateNav from './Components/updateNav'

// ---------- on page load ----------
const onPageLoad = function (isInitialLoad = false) {
	// ---------- nav ----------
	updateNav()

	// ---------- docked menu ----------
	checkIfIsFixedNav()

	// ---------- csrf tokens ----------
	window.csrfTokenName = document.querySelector('meta[name="csrf-name"]').getAttribute('content')
	window.csrfTokenValue = document.querySelector('meta[name="csrf-value"]').getAttribute('content')

	// ---------- sliders ----------
	if (!isInitialLoad) {
		flickitySliders.destroy()
	}
	flickitySliders.init()

	// ---------- video cover ----------
	if (!isInitialLoad) {
		videoCover.destroy()
	}
	videoCover.init()

	// ---------- responsive videos ----------
	if (!isInitialLoad) {
		responsiveVideos.destroy()
	}
	responsiveVideos.init()

	// ---------- lightbox ----------
	lightbox.init()
	if (!isInitialLoad) {
		//attempt to focus the page's h1
		let h1El = document.querySelector('h1')
		if (h1El) {
			h1El.tabIndex = -1
			h1El.focus()
		}
	}

	// ---------- content lightbox ----------
	if (!isInitialLoad) {
		contentLightBox.destroy()
	}
	contentLightBox.init()

	// ---------- sliders ----------
	if (!isInitialLoad) {
		switcher.destroy()
	}
	switcher.init()
}
onPageLoad(true)

document.addEventListener('pjax:success', () => {
	onPageLoad(false)
})
document.addEventListener('pjax:send', e => {
	// ---------- nav ----------
	//close the nav unless going to the homepage
	let triggerEl = e.triggerElement
	if (typeof triggerEl !== 'undefined' && triggerEl.hasOwnProperty('pathname') && triggerEl.pathname !== '/') {
		nav.close()
	}

	// ---------- lightbox ----------
	lightbox.close()

	// ---------- topbar ----------
	topbar.show()
})

document.addEventListener('pjax:complete', topbar.hide)

// ---------- pjax ----------
import Pjax from 'pjax'
new Pjax({
	elements:
		'a[href]:not(.no-pjax):not([href*=".gif"]):not([href*=".jpg"]):not([href*=".png"]):not([href*=".pdf"]):not([href*=".json"]), form[method="get"]',
	selectors: [
		'head > title',
		'meta[name="description"]',
		'meta[name="csrf-name"]',
		'meta[name="csrf-value"]',
		'.main',
	],
	cacheBust: false,
})
