/**
 * Pads a number.
 *
 * See https://stackoverflow.com/a/10073788/1136822
 *
 * @param num
 * @param width
 * @param char
 * @return {string}
 */
export default function (num, width, char = '0') {
	num = num + '';
	return num.length >= width ? num : new Array(width - num.length + 1).join(char) + num;
}
