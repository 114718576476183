// ---------- Flickity Slider ----------

//see https://flickity.metafizzy.co
import Flickity from 'flickity'

import pad from '../Utils/pad'

import isIE11 from '../Utils/isIE11'

//Flickity equal cell height hack
//see https://codepen.io/desandro/pen/ZXEGVq
Flickity.prototype._createResizeClass = function () {
	this.element.classList.add('flickity-ready')
}

Flickity.createMethods.push('_createResizeClass')

var resize = Flickity.prototype.resize
Flickity.prototype.resize = function () {
	this.element.classList.remove('flickity-ready')
	resize.call(this)
	this.element.classList.add('flickity-ready')
}

let initiatedSliders = []

export default {
	init({
		sliderSelector = '[data-flickity-slider]',
		attribute = 'data-flickity-slider',
		slideSelector = '.slide',
	} = {}) {
		let flickitySliders = document.querySelectorAll(sliderSelector)
		if (flickitySliders.length) {
			flickitySliders.forEach(flickitySlider => {
				let sliderType = flickitySlider.getAttribute(attribute)

				let slides = flickitySlider.querySelectorAll(slideSelector)

				//default settings
				let settings = {
					autoPlay: false,
					contain: true,
					pageDots: false,
					prevNextButtons: slides.length > 1,
					wrapAround: true,
				}

				if (sliderType === 'hero') {
					let autoPlay = flickitySlider.getAttribute('data-autoplay') || '0'
					autoPlay = parseInt(autoPlay, 10) * 1000

					settings = {
						autoPlay,
						pauseAutoPlayOnHover: false,
						contain: false,
						pageDots: slides.length > 1,
						prevNextButtons: slides.length > 1,
						wrapAround: true,
						arrowShape:
							'M18.13,54.733l30.279,30.279l-6.7,6.7c-13.899,-13.905 -27.804,-27.81 -41.709,-41.715l41.709,-41.709l6.7,6.7l-30.279,30.273l81.87,0l0,9.472l-81.87,0Z',
					}
				}

				//set up the main slider
				let slider = new Flickity(flickitySlider, settings)
				initiatedSliders.push(slider)

				if (sliderType === 'hero') {
					//load the video iframe
					/*const loadTheIframe = function (ind = 0) {
						if (slides.length && slides.length > ind) {
							let currentSlide = slides[ind]
							if (currentSlide) {
								let currentSlideIframe = currentSlide.querySelector('iframe')
								if (currentSlideIframe) {
									let iframeSrc = currentSlideIframe.getAttribute('src')
									if (!iframeSrc) {
										let iframeDataSrc = currentSlideIframe.getAttribute('data-src')
										if (!!iframeDataSrc) {
											currentSlideIframe.setAttribute('src', iframeDataSrc)
										}
									}
								}
							}
						}
					}*/

					//listen to the events and update
					let counterEl = flickitySlider.parentElement.querySelector('[data-slide-counter]')

					let updateCounter = function () {
						if (counterEl) {
							counterEl.innerHTML = pad(slider.selectedIndex + 1, 2) + ' / ' + pad(slider.cells.length, 2)
						}
					}
					slider.on('ready', updateCounter)
					slider.on('select', ind => {
						updateCounter()
						//loadTheIframe(ind)
					})
					updateCounter()
					//loadTheIframe()
				}
			})
		}
	},
	destroy() {
		if (initiatedSliders.length) {
			initiatedSliders.forEach(initiatedSlider => {
				if (isIE11) {
					initiatedSlider = null
				} else {
					try {
						initiatedSlider.destroy()
					} catch (e) {
						console.log('flickity destroy error', e)
					}
				}
			})
		}
		initiatedSliders = []
	},
}
