/**
 * Manages listeners that they can be easily destroyed later.
 *
 * @copyright Aaron Waldon <aaron@causingeffect.com> 2019
 */
export default class ListenerManager {
	/**
	 * The tracked listeners.
	 * @type {Array}
	 * @private
	 */
	_tracked = [];

	/**
	 * Registers and tracks an event listener.
	 *
	 * @param target
	 * @param type
	 * @param listener
	 * @param options
	 */
	register(target, type, listener, options = {}) {
		target.addEventListener(type, listener, options);
		this.track(target, type, listener, options);
	}

	/**
	 * Tracks an event listener
	 * @param target
	 * @param type
	 * @param listener
	 * @param options
	 */
	track(target, type, listener, options = {}) {
		this._tracked.push({target, type, listener, options});
	}

	/**
	 * Registers and tracks an event listener.
	 *
	 * @param target
	 * @param type
	 * @param listener
	 * @param options
	 */
	remove(target, type, listener, options = {}) {
		target.removeEventListener(type, listener, options);
		this._tracked.forEach((tracked, trackedIndex) => {
			if (tracked === {target, type, listener, options}) {
				this._tracked.splice(trackedIndex, i);
			}
		});
	}

	/**
	 * Remove all items.
	 */
	removeAll() {
		this._tracked.forEach(item => {
			item.target.removeEventListener(item.type, item.listener, item.options);
		});
		this._tracked = [];
	}
}
