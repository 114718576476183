export default function () {
	let navLinks = document.querySelectorAll('.nav ul a, .nav ul button, .mobileNav ul a, .mobileNav ul button');
	let nav = document.querySelector('.nav');
	let navParents = document.querySelectorAll('.nav .hasChildren, .mobileNav .hasChildren');

	// ---------- nav ----------
	//handle the homepage nav styling
	let currentUrl = window.location.href.split("#")[0];
	let isHomepage = (window.location.pathname === '/');
	if (nav) {
		if (isHomepage) {
			nav.classList.add('nav--home');
		} else {
			nav.classList.remove('nav--home');
		}
	}

	//handle the selected states
	if (navLinks.length) {
		navLinks.forEach(navLink => {
			if (navLink.href === currentUrl) {
				navLink.classList.add('selected');
			} else if (currentUrl.indexOf(navLink.href) > -1) {
				navLink.classList.add('selected');
			} else {
				navLink.classList.remove('selected');
			}

			if (navLink.href === currentUrl) {
				navLink.setAttribute('aria-current', 'page');
			} else {
				navLink.removeAttribute('aria-current');
			}
		});
	}

	//make sure nav parents are set as active parents if they have an active child
	if (navParents.length) {
		navParents.forEach(navParent => {
			let hasSelectedChild = !!navParent.querySelector('.selected');
			let firstLink = navParent.querySelector(':scope > a, :scope > button');
			if (firstLink) {
				if (hasSelectedChild) {
					navParent.classList.add('activeParent');
					firstLink.classList.add('selected');
					firstLink.setAttribute('aria-expanded', 'true');
				} else {
					navParent.classList.remove('activeParent');
					firstLink.classList.remove('toggle--open');
					firstLink.setAttribute('aria-expanded', 'false');
				}
			}
		});
	}
}
