import debounce from 'debounce'
import ListenerManager from './listenerManager'

let listenerBoss = new ListenerManager()
let initAttribute = 'data-video-ready'

export default {
	init(selector = '[data-video-cover-holder]') {
		let holderEls = document.querySelectorAll(selector)
		holderEls.forEach(holderEl => {
			if (!holderEl.hasAttribute(initAttribute)) {
				//add the attribute
				holderEl.setAttribute(initAttribute, 'y')

				let videoEl = holderEl.querySelector('video, iframe')

				if (videoEl) {
					let videoWidth = (holderEl.getAttribute('data-width') || 16) * 1
					let videoHeight = (holderEl.getAttribute('data-height') || 9) * 1
					let videoRatio = videoWidth / videoHeight

					const resizeVideo = () => {
						if (holderEl) {
							let box = holderEl.getBoundingClientRect()
							let actualWidth = box.width
							let actualHeight = box.height
							let actualRatio = actualWidth / actualHeight

							if (actualRatio < videoRatio) {
								videoEl.style.height = Math.ceil(actualHeight) + 'px'
								videoEl.style.width = Math.ceil(actualHeight * videoRatio) + 'px'
							} else {
								videoEl.style.width = Math.ceil(actualWidth) + 'px'
								videoEl.style.height = Math.ceil(actualWidth / videoRatio) + 'px'
							}
						} else {
							;['DOMContentLoaded', 'load', 'resize'].forEach(function (eventName) {
								listenerBoss.remove(window, eventName, debounced)
							})
						}
					}

					const debounced = debounce(resizeVideo, 10, false)

					//watch
					;['DOMContentLoaded', 'load', 'resize'].forEach(function (eventName) {
						listenerBoss.register(window, eventName, debounced)
					})
					resizeVideo()
				}
			}
		})
	},
	destroy() {
		listenerBoss.removeAll()
	},
}
