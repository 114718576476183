import ListenerManager from './listenerManager';
let listenerBoss = new ListenerManager();

/**
 * Change the page's location.
 *
 * @param e
 */
const navigateToUrl = e => {
	document.location.href = e.target.value;
};

export default {
	init(selector = '[data-select-url]') {
		let els = document.querySelectorAll(selector);
		if (els.length) {
			els.forEach(el => {
				listenerBoss.register(el,'change', navigateToUrl);
			});
		}
	},
	destroy() {
		listenerBoss.removeAll();
	}
}
