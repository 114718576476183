import ListenerManager from './listenerManager';
let listenerBoss = new ListenerManager();
import whichTransitionEvent from '../Utils/whichTransition';

let transitionEvent = whichTransitionEvent();

export default class ContentLightbox {
	_launcherSelector;
	_closeSelector;
	_openedClass;
	_inClass = false;
	_onOpenCallback = function() {};
	_onCloseCallback = function() {};

	constructor({
            launcherSelector = '[data-content-lightbox]',
            closeSelector = '[data-content-lightbox-close]',
            openClass = 'contentLightbox--open',
            transitionInClass = false,
            transitionOutClass = false,
            onOpen = function(){},
            onClose = function(){}
        } = {}) {
		this._inClass = transitionInClass;
		this._launcherSelector = launcherSelector;
		this._closeSelector = closeSelector;
		this._openedClass = openClass;
		this._onOpenCallback = onOpen;
		this._onCloseCallback = onClose;
	}

	init() {
		let that = this;

		let els = document.querySelectorAll(this._launcherSelector);
		if (els.length) {
			els.forEach(el => {
				let id = el.getAttribute('data-content-lightbox');
				let lightbox = document.querySelector('#'+id);
				if (lightbox) {
					document.body.appendChild(lightbox);

					//open items
					listenerBoss.register(el, 'click', function(e) {
						e.preventDefault();
						lightbox.classList.add(that._openedClass);

						if (that._inClass) {
							setTimeout(function(){
								lightbox.classList.add(that._inClass);
							}, 5);
						}

						//call the callback
						if (that._onOpenCallback) {
							that._onOpenCallback(lightbox);
						}

						return false;
					});

					lightbox.querySelectorAll(that._closeSelector).forEach(closeEl => {
						listenerBoss.register(closeEl, 'click', function (e) {
							e.preventDefault();

							if (that._inClass) {
								let removeOpenClass = function(e) {
									if (e.target && e.target === lightbox) {
										lightbox.classList.remove(that._openedClass);
										listenerBoss.remove(lightbox, transitionEvent, removeOpenClass);
									}
								};
								listenerBoss.register(lightbox, transitionEvent, removeOpenClass);
								lightbox.classList.remove(that._inClass);

							} else {
								lightbox.classList.remove(that._openedClass);
							}

							//call the callback
							if (that._onCloseCallback) {
								that._onCloseCallback(lightbox);
							}
						})
					});
				}
			});

			//close each lightbox if the escape key is pressed
			listenerBoss.register(window, 'keydown', e => {
				if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
					this.closeAll();
				}
			});
		}
	}

	closeAll() {
		//close the lightboxes
		let els = document.querySelectorAll('.' + this._openedClass);
		if (els.length) {
			els.forEach(el => {
				el.classList.remove(this._openedClass);
				if (this._inClass) {
					el.classList.remove(this._inClass);
				}

				if (this._onCloseCallback) {
					this._onCloseCallback(el);
				}
			});
		}
	}

	destroy() {
		listenerBoss.removeAll();
		this.closeAll();
	}
}
